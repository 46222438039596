@import url('https://fonts.googleapis.com/css2?family=Hammersmith+One&display=swap&family=Hammersmith+One&family=Londrina+Solid:wght@900&display=swap');
.bg-planet-svg {
    position: absolute;
    top: 250px;
    right: -205px;
    z-index: -5;
    width: 450px;
}

.bg-planet2-svg {
    position: absolute;
    bottom: -125px;
    left: -180px;
    z-index: -5;
    width: 450px;
    opacity: 0.8;
}

.bg-planet3-svg {
    position: absolute;
    top: -65px;
    left: -170px;
    z-index: -5;
    width: 450px;
    opacity: 0.8;
}

.story-card-wrapper {
    position: relative;
    min-height: 850px;
    width: 1050px;
    margin: 1.2rem auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.story-card {
    width: 30%;
    position: relative;
    height: 460px;
    border-radius: 6px;
    margin: 2rem 1rem;
    border: 1px solid rgba(121, 118, 118, 0.3);
    box-shadow: 0 0 10px 5px rgba(88, 88, 88, 0.1);
    background-color: white;
    transition: 350ms ease all;
}

@media only screen and (max-width: 1050px) {
    .story-card-wrapper {
        width: 100%;
        justify-content: space-evenly;
    }
    .story-card {
        width: 45%;
    }
    .bg-planet3-svg,
    .bg-planet-svg,
    .bg-planet2-svg {
        display: none;
    }
}

@media only screen and (max-width: 674px) {
    .story-card {
        width: 380px
    }
}

.story-card:hover {
    transform: scale(1.1);
}

.story-card a.story-link {
    text-decoration: none;
}

.story-card img.story-image {
    width: 100%;
    height: 225px;
    border-radius: 6px 6px 0 0;
    object-fit: cover;
}

.story-card .story-content-wrapper {
    padding: 14px;
}

.story-card h5.story-title {
    font-size: 1.4rem;
    line-height: 30px;
    height: 87px;
    letter-spacing: 0.6px;
    color: black;
    padding: 10px 3px;
    font-weight: bold;
    margin-bottom: 1.2rem;
    word-wrap: break-word;
    font-family: 'Hammersmith One', sans-serif;
    font-family: 'Londrina Solid', cursive;
}

.story-card .story-text {
    height: 55px;
    overflow: hidden;
    word-wrap: break-word;
}

.story-card .story-text,
.story-card .story-text * {
    color: rgb(92, 92, 92);
    font-size: 1rem;
    font-weight: normal;
}

.story-card .story-createdAt {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: rgb(48, 47, 47);
}